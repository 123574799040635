import React, { useState, useEffect } from "react";
import {
  Carousel,
  CarouselContent,
  CarouselItem,
  CarouselNext,
  CarouselPrevious,
} from "@/components/ui/carousel";
import {
  Dialog,
  DialogContent,
  //DialogDescription,
  DialogHeader,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import {
  Table,
  TableBody,
  //TableCaption,
  TableCell,
  //TableHead,
  //TableHeader,
  TableRow,
} from "@/components/ui/table";
import { cn } from "@/lib/utils";

interface ShowcaseProps extends React.HTMLAttributes<HTMLDivElement> {
  src: string;
  nearestNeighbors?: number;
  className?: string;
}

interface Prediction {
  "Predicted cell type": string;
  "Classification certainty": number;
  "Class distribution": Record<string, number>;
}

function getTopKClassDistribution(
  classDistribution: Record<string, number>,
  k = 3
) {
  // Convert the object into an array of [key, value] pairs and sort by value in descending order
  const sortedDistribution = Object.entries(classDistribution).sort(
    (a, b) => b[1] - a[1]
  );

  // Return the top k pairs
  return sortedDistribution.slice(0, k);
}

const Showcase = ({
  src,
  nearestNeighbors = 5,
  className = "",
  ...props
}: ShowcaseProps) => {
  const [prediction, setPrediction] = useState<Prediction>({
    "Predicted cell type": "",
    "Classification certainty": 0.0,
    "Class distribution": {
      Basophile: 0.0,
      Eosinophile: 0.0,
      Lymphoblast: 0.0,
      Lymphocyte: 0.0,
      Monocyte: 0.0,
      Myeloblast: 0.0,
      Neutrophile: 0.0,
      Normoblast: 0.0,
    },
  });

  const thumbnailSrc = `${src}/thumbnail.png`;
  const nnSources = Array.from(
    { length: nearestNeighbors },
    (_, i) => `${src}/nearest_${i}.png`
  );

  async function fetchPrediction() {
    const response = await fetch(`${src}/prediction.json`);
    const data = await response.json();
    setPrediction(data);
  }

  useEffect(() => {
    fetchPrediction();
  }, [src]);

  return (
    <div
      className={cn(
        "flex flex-col items-center justify-center space-y-4",
        className
      )}
      {...props}
    >
      <Dialog>
        <DialogTrigger>
          <div className="w-72 h-72 overflow-hidden rounded-md">
            <img
              src={thumbnailSrc}
              alt="Thumbnail of a cell"
              className="w-72 h-72 duration-300 hover:scale-110 hover:brightness-75 object-cover"
            />
          </div>
        </DialogTrigger>
        <DialogContent className="min-w-80 max-w-xl px-8">
          <DialogHeader>
            <DialogTitle className="text-center pb-4">Prediction</DialogTitle>
            <div className="flex justify-center">
              <img
                src={thumbnailSrc}
                alt="Thumbnail of a cell"
                className="w-64 h-64 sm:w-80 sm:h-80"
              />
            </div>
            <div id="inference-statistics" className="flex justify-center py-4">
              <Table>
                <TableBody>
                  <TableRow>
                    <TableCell className="font-medium">
                      Predicted cell type:
                    </TableCell>
                    <TableCell>
                      <strong>{prediction["Predicted cell type"]}</strong>
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell className="font-medium">
                      Classification certainty:
                    </TableCell>
                    <TableCell>
                      <strong>
                        <strong>
                          {prediction["Classification certainty"]}
                        </strong>
                      </strong>
                    </TableCell>
                  </TableRow>
                  {getTopKClassDistribution(
                    prediction["Class distribution"]
                  ).map((keyValue) => (
                    <TableRow key={keyValue[0]}>
                      <TableCell className="font-medium">
                        {keyValue[0]}:
                      </TableCell>
                      <TableCell>
                        <strong>{keyValue[1]}</strong>
                      </TableCell>
                    </TableRow>
                  ))}
                </TableBody>
              </Table>
            </div>
            <div className="flex justify-center">
              <Carousel
                opts={{
                  slidesToScroll: 1,
                  skipSnaps: true,
                }}
                className="w-3/4"
              >
                <CarouselContent>
                  {nnSources.map((src, i) => (
                    <CarouselItem key={i} className="flex justify-center">
                      <img
                        src={src}
                        alt={`Nearest Neighbor ${i + 1}`}
                        className="w-64 h-64"
                      />
                    </CarouselItem>
                  ))}
                </CarouselContent>
                <CarouselPrevious />
                <CarouselNext />
              </Carousel>
            </div>
          </DialogHeader>
        </DialogContent>
      </Dialog>
    </div>
  );
};

export default Showcase;

import { cn } from "@/lib/utils";
import { Button } from "@/components/ui/button";
import {
  Tooltip,
  TooltipContent,
  TooltipProvider,
  TooltipTrigger,
} from "@/components/ui/tooltip";

interface FilterBarProps extends React.HTMLAttributes<HTMLDivElement> {
  datasets: Array<string>;
  datasetDescriptions: Record<string, string>;
  datasetFilter: string;
  setDatasetFilter: (dataset: string) => void;
}

const FilterBar = ({
  datasets,
  datasetDescriptions,
  datasetFilter,
  setDatasetFilter,
}: FilterBarProps) => {
  return (
    <div>
      <Button
        onClick={() => {
          setDatasetFilter("All");
        }}
        className={cn(
          "text-xs h-7 rounded-full mx-1",
          datasetFilter === "All"
            ? "bg-primary text-white"
            : "bg-white text-primary hover:bg-secondary"
        )}
      >
        All
      </Button>
      {datasets.map((dataset) => (
        <TooltipProvider>
          <Tooltip>
            <TooltipTrigger>
              <Button
                onClick={() => {
                  setDatasetFilter(dataset);
                }}
                className={cn(
                  "text-xs h-7 rounded-full mx-1",
                  datasetFilter === dataset
                    ? "bg-primary text-white"
                    : "bg-white text-primary hover:bg-secondary"
                )}
              >
                {dataset}
              </Button>
            </TooltipTrigger>
            <TooltipContent>{datasetDescriptions[dataset]}</TooltipContent>
          </Tooltip>
        </TooltipProvider>
      ))}
    </div>
  );
};

export default FilterBar;

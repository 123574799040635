import { useState, useEffect, useRef } from "react";
import { cn } from "./lib/utils";

import Showcase from "./components/custom/showcase";
import { Button } from "./components/ui/button";
import BibTexRecord from "./components/custom/bibtex";
import "./index.css";
import FilterBar from "./components/custom/filterbar";

const showcaseNames: Record<string, Array<string>> = {
  LISC: ["lisc_Basophil_0_42", "lisc_Lymphocyte_0_39", "lisc_Monocyte_0_26"],
  WBC: [
    "Basophile_ 205",
    "Lymphoblast_1017",
    "Lymphocyte_1775",
    "Neutrophile Segment_1144",
  ],
  "WBC-Modified": [
    "Myeloblast_2048_cartoon",
    "Myeloblast_2048_clothify",
    "Myeloblast_2048_copy",
    "Myeloblast_2048_glass",
    "Myeloblast_2048_glow",
    "Myeloblast_2048_oil",
    "Neutrophile Segment_1080_cartoon",
    "Neutrophile Segment_1080_clothify",
    "Neutrophile Segment_1080_copy",
    "Neutrophile Segment_1080_glass_tile",
    "Neutrophile Segment_1080_glow",
    "Neutrophile Segment_1080_inv",
    "Neutrophile Segment_1080_oil",
    "Neutrophile Segment_1080_wb",
    //"Neutrophile Segment_1080_wp",
  ],
  PBC: ["pbc_BA_307542", "pbc_LY_415354", "pbc_NEUTROPHIL_183270"],
};

const datasets = ["LISC", "PBC", "WBC", "WBC-Modified"];
const datasetDescriptions: Record<string, string> = {
  LISC: "Leukocyte Images for Segmentation and Classification (LISC) dataset",
  WBC: "White Blood Cell (WBC) dataset",
  "WBC-Modified": "White Blood Cell (WBC) dataset",
  PBC: "Acevedo et al., A dataset of microscopic peripheral blood cell images for development of automatic recognition systems, Data Brief. 8. April 2020",
};

const filterShowCases = (dataset: string) => {
  if (dataset === "All") {
    return Object.values(showcaseNames).flat();
  } else {
    return showcaseNames[dataset];
  }
};

function App() {
  const [datasetFilter, setDatasetFilter] = useState<string>("All");
  const [delay, setDelay] = useState<number>(1300);

  const abstractRef = useRef<HTMLDivElement>(null);
  useEffect(() => {
    const observer = new IntersectionObserver((entries) => {
      entries.forEach(
        (entry) => {
          if (entry.isIntersecting) {
            entry.target.classList.add("animate-upperMoveFadeIn");
            entry.target.classList.remove("opacity-0");
          }
        },
        {
          rootMargin: "0px",
          threshold: 1.0,
        }
      );
    });
    if (abstractRef.current) {
      observer.observe(abstractRef.current);
    }
  }, [abstractRef]);

  return (
    <>
      <div className="px-4 md:px-8">
        <nav className="flex pt-4">
          <div className="flex animate-leftSlideIn">
            <h1 className="text-2xl font-bold">CytomAIte</h1>
            <img
              src="/assets/cytomaite_logo.png"
              className="w-8 h-8 animate-happyCellDance delay-300"
            />
          </div>
          <div className="flex justify-end w-full animate-rightSlideIn">
            <Button>
              <a href="https://live.cytomaite.criion.org/">
                <span>Login</span>
              </a>
            </Button>
          </div>
        </nav>
        <div
          id="introduction"
          className="px-4 md:px-8 py-4 md:py-8 animate-upperMoveFadeIn opacity-0"
        >
          <div className="bg-primary rounded-3xl py-8">
            <p
              className="text-white text-center text-xs font-medium px-2 pb-2
                md:text-lg md:px-16"
            >
              Welcome to Cytomaite, an AI-powered research tool for analyzing
              stained white blood cell populations. Cytomaite utilizes vision
              foundation models for identifying cell types and states across
              different imaging devices. Cytomaite helps researchers gain
              insights into cell populations, with potential applications in
              cancer therapy and other areas of cellular research. Explore how
              Cytomaite can support your research efforts.
            </p>
          </div>
        </div>
        <main className="flex justify-center px-4 md:px-8">
          <div className="w-full">
            <div
              className="flex justify-end opacity-0 animate-fadeIn"
              style={{
                animationDelay: `1300ms`,
              }}
            >
              <FilterBar
                datasets={datasets}
                datasetDescriptions={datasetDescriptions}
                datasetFilter={datasetFilter}
                setDatasetFilter={(dataset) => {
                  setDatasetFilter(dataset);
                  setDelay(0);
                }}
              />
            </div>
            <div className="flex flex-wrap justify-center">
              {filterShowCases(datasetFilter).map((name, idx) => (
                <Showcase
                  key={`showcase#${name}`}
                  src={`/assets/gallery/${name}`}
                  className={cn(
                    "px-2 py-2",
                    "opacity-0 animate-upperMoveFadeIn"
                  )}
                  style={{
                    animationDelay: `${delay + 100 * idx}ms`,
                  }}
                ></Showcase>
              ))}
            </div>
          </div>
        </main>
      </div>
      <div
        id="abstract"
        ref={abstractRef}
        className="px-4 md:px-8 py-4 md:py-8"
      >
        <h1
          className="text-center text-lg 
                   sm:text-2xl 
                   md:text-3xl md:px-16
                   lg:text-5xl lg:px-16 py-2 lg:py-4"
        >
          Unsupervised Feature Extraction from a Foundation Model Zoo for Cell
          Similarity Search in Oncological Microscopy Across Devices
        </h1>
        <p
          className="text-center text-xs px-4 pb-2
                md:text-lg md:px-32"
        >
          Gabriel Kalweit, Anusha Klett, Mehdi Naouar, Jens Rahnfeld, Yannick
          Vogt, Diana Laura Infante Ramirez, Rebecca Berger, Jesus Duque Afonso,
          Tanja Nicole Hartmann, Marie Follo, Michael Luebbert, Roland
          Mertelsmann, Evelyn Ullrich, Joschka Boedecker and Maria Kalweit
        </p>
        <div className="flex justify-center pt-1 md:pt-4 animate-fadeIn">
          <Button className="mr-1">
            <a
              href="https://openreview.net/forum?id=wctboDvy9f"
              target="_blank"
            >
              <span>Read paper</span>
            </a>
          </Button>
          <BibTexRecord
            triggerElement={
              <Button>
                <span>BibTeX</span>
              </Button>
            }
          />
        </div>
      </div>
      <footer className="flex justify-center py-4 md:py-8 mt-4 md:mt-8 bg-primary animate-fadeIn">
        <div className="flex items-center pr-2">
          <span className="text-xs md:text-base text-white">Supported by</span>
        </div>
        <img
          src="/assets/criion_logo.png"
          className="h-6 md:h-12 px-2 md:px-8"
        />
        <img src="/assets/mertelsmann_logo.png" className="h-6 md:h-12" />
      </footer>
    </>
  );
}

export default App;

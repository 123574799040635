import { useState } from "react";
import { Button } from "@/components/ui/button";
import {
  Dialog,
  DialogContent,
  DialogTitle,
  DialogTrigger,
} from "@/components/ui/dialog";
import { CopyIcon, CheckIcon } from "lucide-react";

const bibTeX: string = `@inproceedings{
kalweit2024unsupervised,
title={Unsupervised Feature Extraction from a Foundation Model Zoo for Cell Similarity Search in Oncological Microscopy Across Devices},
author={Gabriel Kalweit and Anusha Klett and Mehdi Naouar and Jens Rahnfeld and Yannick Vogt and Diana Laura Infante Ramirez and Rebecca Berger and Jesus Duque Afonso and Tanja Nicole Hartmann and Marie Follo and Michael Luebbert and Roland Mertelsmann and Evelyn Ullrich and Joschka Boedecker and Maria Kalweit},
booktitle={ICML 2024 Workshop on Foundation Models in the Wild},
year={2024},
url={https://openreview.net/forum?id=wctboDvy9f}
}`;

interface BibTexRecordProps extends React.HTMLAttributes<HTMLDivElement> {
  triggerElement: React.ReactNode;
}

const BibTexRecord = ({ triggerElement }: BibTexRecordProps) => {
  const [copyButtonIcon, setCopyButtonIcon] = useState<React.ReactNode>(
    <CopyIcon />
  );

  const copyToClipboard = async () => {
    await navigator.clipboard.writeText(bibTeX);
    setCopyButtonIcon(<CheckIcon />);
    setTimeout(() => setCopyButtonIcon(<CopyIcon />), 1000);
  };

  return (
    <Dialog>
      <DialogTrigger>{triggerElement}</DialogTrigger>
      <DialogContent>
        <DialogTitle>BibTeX Record</DialogTitle>
        <div className="overflow-auto bg-gray-50 px-4">
          <pre className="py-4">{bibTeX}</pre>
        </div>
        <div className="flex justify-end">
          <Button onClick={copyToClipboard}>{copyButtonIcon}</Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

export default BibTexRecord;
